<template>
    <el-scrollbar class="education-wrapper" :native="false">
        <div class="steps-wrapper">
            <div class="step-item" :class="{current: [1, 2, 3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">创建热门关键词</div>
            </div>
            <div class="step-item" :class="{current: [2, 3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">添加热门关键词</div>
            </div>
            <div class="step-item" :class="{current: [3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">3</div>
                <div class="step-title">{{createMessage}}</div>
            </div>
        </div>
        <div class="create-content" v-if="stepActive === 1">
            <el-form :model="keywordForm" :rules="keywordRules" ref="keywordForm" label-width="120px">
                <el-form-item label="创建名称" prop="name">
                    <el-input v-model="keywordForm.name" placeholder="1-30个字" clearable style="width: 380px"></el-input>
                </el-form-item>
                <el-form-item label="行业分类" prop="category_id">
                    <el-select v-model="keywordForm.category_id" placeholder="请选择行业分类" clearable style="width: 380px;margin-right: 10px;">
                        <el-option v-for="categoryItem in categoryList" :key="categoryItem.id" :label="categoryItem.name" :value="categoryItem.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="keywordForm.remark" placeholder="1-30个字" style="width: 380px"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="create-content" v-if="stepActive === 2">
            <div class="handel-keyword-btn">
                <div class="keyword-btn">
                    <el-button class="btn-red-key" @click="delMultipleBtn">删除</el-button>
                    <el-button class="btn-blue-key" @click="importKeyword" style="margin-right: 10px">导入关键词</el-button>
                    <input type="file" ref="excelInput" accept="excel/*" title="" @change="changeExcel($event)" style="display: none;">
                    <el-button class="btn-blue-key" @click="exportKeyword">导出关键词</el-button>
                    <el-button class="btn-blue-key" @click="downloadTemplate">下载模板</el-button>
                </div>
                <div class="sue-official-data">
                    使用官方数据
                    <el-select v-model="officialDataValue" placeholder="请选择" style="margin-left: 10px;" @change="selectFD">
                        <el-option
                                v-for="item in officialDataOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="key-word-tb">
                <el-table  v-loading = "pictLoading"
                element-loading-text = "数据正在加载中"
                element-loading-spinner = "el-icon-loading"
                :data="secondKeywordList" border style="width: 100%; margin: 10px 0; flex: 1" height="100%" size="medium"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                          :cell-style="{fontSize: '14px',color: '#343441'}" @selection-change="handleSelectionKeyword">
                    <el-table-column
                            type="selection"
                            width="50">
                    </el-table-column>
                    <el-table-column prop="keyword" align="center" label="关键词">
                        <template slot-scope="scope">
                            <el-input
                                      v-model="scope.row.keyword"
                                      style="width: 100%"
                                      @blur="saveItem(scope.row.keyword)">
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="search_num" align="center" label="搜索量">
                        <template slot-scope="scope">
                            <el-input-number
                                    v-model="scope.row.search_num"
                                    @change="inputVal"
                                    :min="1"
                                    :max="99999999999"
                                    :controls="false"
                                    :precision="0"
                                    style="width: 100%"
                                    @blur="saveItem(scope.row.search_num)">
                            </el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column prop="click_rate" align="center" label="点击率">
                        <template slot-scope="scope">
                            <el-input-number
                                    v-model="scope.row.click_rate"
                                    @change="inputVals"
                                    style="width: 100%"
                                    @blur="saveItem(scope.row.click_rate)"
                                    :min="0.01"
                                    :max="100"
                                    :controls="false"
                                    :precision="2">
                            </el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column prop="tran_rate" align="center" label="转化率">
                        <template slot-scope="scope">
                            <el-input-number
                                    v-model="scope.row.tran_rate"
                                    @change="inputVals"
                                    style="width: 100%"
                                    @blur="saveItem(scope.row.tran_rate)"
                                    :min="0.01"
                                    :max="100"
                                    :controls="false"
                                    :precision="2">
                            </el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-link :underline="false" type="danger" @click="delBtn(scope.$index, scope.row)">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="handel-keyword-btn">
                <el-button class="btn-blue-key" @click="manualAddKeyword">添加新关键词</el-button>
            </div>
        </div>
        <div class="create-content" v-if="stepActive === 3">
            <div class="success-content">
                <i class="iconfont">&#xe730;</i>
                <p class="text">{{createMessage}}</p>
            </div>
        </div>
        <div class="bottom-btn">
            <el-button @click="prevBtn(stepActive)"  v-if="stepActive === 1 || stepActive === 2">上一步</el-button>
            <el-button @click="prevBtn(stepActive)"  v-if="stepActive === 3">返回</el-button>
            <el-button class="btn-blue" @click="nextBtn(stepActive, 'keywordForm')"  v-if="stepActive === 1">下一步</el-button>
            <el-button class="btn-blue" @click="nextBtn(stepActive)"  v-if="stepActive === 2">下一步</el-button>
        </div>
    </el-scrollbar>
</template>

<script>
    import _ from "underscore";
    export default {
        name: "CreateHotKeywords",
        data() {
            return {
                stepActive: 1,
                createMessage: '创建成功',
                //第一步表单数据
                keywordForm: {
                    name: '',
                    category_id: '',
                    remark: '',
                    keyword_excel: '',
                    excelFile: '',
                    excelFileName: '',
                },
                keywordRules: {
                    name: [
                        { required: true, message: '请输入关键词名称', trigger: 'blur' },
                        { min: 1, max: 30, message: '长度在 1 到 30 个字', trigger: 'blur' }
                    ],
                    category_id: [
                        { required: true, message: '请选择行业分类', trigger: 'change' }
                    ],
                },
                //行业类表
                categoryList: [],
                //官方数据列表
                officialDataOptions: [],
                officialDataValue: '',
                //第二步要添加的关键词列表
                secondKeywordList: [],
                //选中的行
                selectRow: [],
                //关键词id
                keywordId: Number(this.$route.query.id) || 0,
                tableList:[],//编辑的时候暂存一下关键字信息
                pictLoading:false
            }
        },
        mounted() {
           this.getCategoryList();
           this.getOfficialDataList();
           this.getFindHotKeyword();
        },
        methods: {
            //获取行业分类列表
            getCategoryList() {
                this.$http.axiosGet(this.$api.categoryList, (res) => {
                    if (res.code === 200) {
                        this.categoryList = res.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取官方数据
            getOfficialDataList() {
                this.$http.axiosGet(this.$api.hotKeywordList, (res) => {
                    if (res.code === 200) {
                        this.officialDataOptions = res.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //选择的官方数据
            selectFD(val) {
                //使用官方数据
                this.$http.axiosGetBy(this.$api.useOfficialData, {id: val}, (res) => {
                    if (res.code === 200) {
                        this.secondKeywordList = res.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //导入关键词
            importKeyword() {
                this.$refs.excelInput.click();
            },
            changeExcel(event) {
                if (event.target.files.length === 0) return;
                if (event.target.files[0].size > 1024 * 1024 * 200) {
                    this.$message.warning('文件超过200M');
                    return;
                } else {
                    let fileList = event.target.files[0];
                    this.keywordForm.excelFile = fileList;
                    this.keywordForm.excelFileName = fileList.name;
                    let url = '';
                    let reader = new FileReader();
                    reader.readAsDataURL(fileList);
                    let that = this;
                    reader.onload = function (e) {
                        url = this.result.substring(this.result.indexOf(',') + 1);
                        that.keywordForm.keyword_excel = "data:excel/*," + url;
                    }
                }
                let formData = new FormData();
                formData.append('excel', this.keywordForm.excelFile);
                this.$http.axiosPost(this.$api.importHotKeyword, formData, (res) => {
                    if (res.code === 200) {
                        this.secondKeywordList = res.data;
                        this.$message.success(res.msg);
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取要导出的关键词
            exportKeyword() {
                let formData = new FormData();
                let keywordArr = [];
                if (this.selectRow.length !== 0) {
                    keywordArr = this.selectRow;
                } else {
                    keywordArr = this.secondKeywordList;
                }
                formData.append('keywords', JSON.stringify(keywordArr));
                this.$http.axiosPost(this.$api.getExportHotKeyword, formData, (res) => {
                    if (res.code === 200) {
                        //导出关键词
                        window.location.href = '/exportHK';
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //下载模板
            downloadTemplate() {
                window.location.href = 'http://match.keketong.xyz/uploads/excel/template/keyword_template.xls';

            },
            //手动添加关键词
            manualAddKeyword() {
                if (this.secondKeywordList === undefined) {
                    this.secondKeywordList = new Array();
                }
                let newAddKeyword = {};
                newAddKeyword.click_rate = null;
                newAddKeyword.keyword = '';
                newAddKeyword.search_num = null;
                newAddKeyword.tran_rate = null;
                this.secondKeywordList.push(newAddKeyword);
            },
            //失焦事件（保存手动添加关键词）
            saveItem(val) {
                if (val === '' || val === null) {
                    this.$message.warning('请填写相关数据');
                    return false;
                }
            },
            //输入的搜索量必须大于0
            inputVal(val) {
                if (Number(val) === 0) {
                    this.$message.warning('输入的搜索量必须大于0！');
                    return false;
                }
            },
            //输入的点击率，转化率必须大于0且小于等于100
            inputVals(val) {
                if (val === Number(val)) {
                    if (Number(100 <= val && val >= 0)) {
                        this.$message.warning('输入的点击率，转化率必须大于0且小于等于100！');
                        return false;
                    }
                } else {
                    this.$message.warning('输入的点击率，转化率必须是数字！');
                }

            },
            //选择的关键词
            handleSelectionKeyword(val) {
                this.selectRow = val;
            },
            //编辑关键词
            getFindHotKeyword() {
                if (this.keywordId) {
                    const _this = this;
                    this.$http.axiosGetBy(this.$api.findHotKeyword, {id: this.keywordId}, (res) => {
                        if (res.code === 200) {
                            this.keywordForm.name = res.data.name;
                            this.keywordForm.category_id = res.data.category_id;
                            this.keywordForm.remark = res.data.remark;
                            // this.secondKeywordList = res.data.keywords_data;
                            this.tableList=res.data.keywords_data;
                        } else {
                            this.$message({
                                message: res.msg,
                                duration: 1000,
                                type: 'warning',
                                onClose: function() {
                                    _this.$router.push('/admin/operatedata/hotkeywords');
                                }
                            });
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
            },
            //删除（单行）
            delBtn(index, row) {
                this.$confirm('是否删除该关键词，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.secondKeywordList.splice(index, 1);
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            //删除（多选）
            delMultipleBtn() {
                this.$confirm('是否删除选中的关键词，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.secondKeywordList = this.secondKeywordList.filter(item => {
                        let arrTmp = this.selectRow.map(selectItem => selectItem.keyword);
                        return arrTmp.includes(item.keyword) === false
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            // 上一步按钮
            prevBtn(index) {
                if (index === 1) {
                    this.$router.go(-1);
                } else if (index === 2) {
                    this.stepActive = 1;
                    this.secondKeywordList=[]                  
                } else if (index === 3) {
                    this.$router.push('/admin/operatedata/hotkeywords');
                }
            },
            // 下一步按钮
            nextBtn(index, formName) {
                // 第一步的下一步
                if (index === 1) {
                    if (!this.keywordForm.category_id) return this.$message.error("请选择行业分类！");
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.stepActive = 2;
                        //编辑的时候需要让表格实现懒加载，由于数据太多会导致页面卡顿
                        if(this.tableList.length>0){ 
                            this.pictLoading=true; 
                            setTimeout(()=>{
                                this.secondKeywordList=this.tableList
                                this.pictLoading=false
                            },100)}                                     
                        } else {
                            return false;
                        }
                    });
                    // 第二步的下一步
                } else if (index === 2) {
                    if (this.secondKeywordList.length !== 0) {
                        let formData = new FormData();
                        formData.append('name', this.keywordForm.name);
                        formData.append('category_id', this.keywordForm.category_id);
                        formData.append('keywords', JSON.stringify(this.secondKeywordList));
                        if (this.keywordForm.remark) {
                            formData.append('remark', this.keywordForm.remark);
                        }

                        //验证关键词名称
                        let dialog_flag = 0;
                        _.each(this.secondKeywordList, item => {
                            if (!item.keyword) {
                                dialog_flag++;
                                if (dialog_flag === 1) {
                                    return this.$message.warning('请输入关键词');
                                }
                                return
                            }
                        });
                        if (dialog_flag) {
                            return;
                        }

                        if (this.keywordId) {
                            formData.append('id', this.keywordId);
                            this.$http.axiosPost(this.$api.saveHotKeyword, formData, (res) => {
                                if (res.code === 200) {
                                    this.stepActive = 3;
                                    this.createMessage = '编辑成功！';
                                } else {
                                    this.$message.warning(res.msg);
                                }
                            }, (err) => {
                                console.log(err);
                            })
                        } else {
                            this.$http.axiosPost(this.$api.saveHotKeyword, formData, (res) => {
                                if (res.code === 200) {
                                    this.stepActive = 3;
                                    this.createMessage = '创建成功！';
                                } else {
                                    this.$message.warning(res.msg);
                                }
                            }, (err) => {
                                console.log(err);
                            })
                        }
                    } else {
                        this.$message.warning('请选择要添加的关键词！');
                    }

                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .steps-wrapper {
        margin-top: 34px;
    }
    .education-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        /deep/ .el-scrollbar__view {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .education-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
        .create-content {
            margin: 60px 0 30px;
            height: calc(100% - 256px);
            .handel-keyword-btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
                .btn-red-key {
                    color: #EB6547;
                    border-color: #EB6547;
                    padding: 7px 30px;
                    &:focus, &:hover {
                        color: #f78989;
                        border-color: #f78989;
                        background-color: #fff;
                    }
                }
                .btn-blue-key {
                    color: #1122D8;
                    border-color: #1122D8;
                    padding: 7px 29px;
                    &:focus, &:hover {
                        color: #747eea;
                        border-color: #747eea;
                        background-color: #fff;
                    }
                }
            }
            .key-word-tb {
                height: calc(100% - 80px);
            }
            .success-content {
                margin-top: 155px;
                text-align: center;
                .iconfont {
                    color: #1FD18C;
                    font-size: 52px;
                }
                .text {
                    font-size: 24px;
                    margin: 20px 0 0;
                    font-weight: bold;
                }
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
    .bottom-btn {
        text-align: center;
    }
</style>